/* eslint-disable react/no-unescaped-entities */
import { graphql } from 'gatsby'
import React, { useState } from 'react'

import FaqQuestion from '../../components/FaqQuestion'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import '../../less/cms.less'
import UtmContent from '../../models/UtmContent'

const Faq = () => {
  const [open, setOpen] = useState('')

  const toggle = (identifier: string) => {
    setOpen(open === identifier ? '' : identifier)
  }

  return (
    <Layout>
      <SEO title="FAQs" />
      <div className="cms-container">
        <h1 className="cms-container__title">Frequently Asked Questions</h1>
        <div className="questions-container cms-container__wrapper">
          <a className="questions-container__toggle-all" id="toggle-all">
            Expand all
          </a>
          <FaqQuestion
            customClass="question-group--first"
            question="What is MealPal?"
            open={open === '#1'}
            toggle={() => toggle('#1')}
            utmContent={new UtmContent({ position: 'what-is-mp' }).toString()}
          >
            <p>
              MealPal offers a lunch subscription giving you access to the best restaurants for less than $4 per meal.
              We provide a fast, fun, and efficient system to get you delicious and affordable meals every day.
            </p>
            <p>
              MealPal members can reserve meals from any participating restaurant. Each restaurant offers one of their
              signature dishes daily.
            </p>
          </FaqQuestion>
          <FaqQuestion
            question="What are the membership plans and how much do they cost?"
            open={open === '#2'}
            toggle={() => toggle('#2')}
            utmContent={new UtmContent({ position: 'membership-plans' }).toString()}
          >
            <strong>MealPal offers the following lunch subscriptions:</strong>
            <p>
              20 Meal Lunch for as little as $5.68 per meal. With this plan you get 105 credits for $149. <br />
              12 Meal Lunch for as little as $6.09 per meal. With this plan you get 65 credits for $99.
            </p>
            <p>
              Meals are available for between 4 and 10 credits, depending on the retail price of the Meal.{' '}
              <strong>
                You will save 20% to 50% on the regular meal price with MealPal’s exclusive member’s only prices.
              </strong>
            </p>
            <p>
              Unless otherwise noted, plans last for a 30 day cycle and automatically renew after this time or when you
              have less than 10 credits remaining, whichever comes first. You can rollover up to half of your unused
              credits to your next continuous cycle.
            </p>
          </FaqQuestion>
          <FaqQuestion
            question="How do I use MealPal?"
            open={open === '#3'}
            toggle={() => toggle('#3')}
            utmContent={new UtmContent({ position: 'how-to-use' }).toString()}
          >
            <p>
              After subscribing to one of our lunch plans you will be able to start reserving lunch. The MealPal lunch
              kitchen opens daily at 5:00 PM, local time. The lunch kitchen closes at 1:30 PM the next day. Log in while
              the lunch kitchen is open to reserve your lunch. Participating restaurants offer daily lunch options. You
              can reserve up to one lunch item per day. If you wish to change your lunch reservation to a different
              meal, you can cancel your reservation and select another meal as long as you do this in advance of 10:30
              AM.
            </p>
            <p>
              When browsing available meals, note that each meal is shown with a list of ingredients and a photo of the
              actual meal. To reserve your meal, tap on the meal image, select your pickup time, and tap reserve.
            </p>
            <p>
              Your meal will be ready and waiting for you at your designated pick up time. Skip the queue, tell the
              restaurant you are with MealPal and scan to pick up. Enjoy your meal!
            </p>
          </FaqQuestion>
          <FaqQuestion
            question="Where can I use MealPal?"
            open={open === '#4'}
            toggle={() => toggle('#4')}
            utmContent={new UtmContent({ position: 'where-to-use' }).toString()}
          >
            <p>
              You can get meals at any participating restaurant in the MealPal network. To see a list and map of all
              participating restaurants go to{' '}
              <a
                target="_blank"
                href={`${process.env.GATSBY_LANDING_PAGE_URL}/au/#map`}
                rel="noreferrer"
              >{`${process.env.GATSBY_LANDING_PAGE_URL}/au/#map`}</a>
              .
            </p>
            <p>
              MealPal lunch is currently available in Austin, Boston, Chicago, London, Los Angeles, New York City,
              Philadelphia, Portland, San Francisco, Seattle, Toronto, Washington, D.C.
            </p>
            <p>Interested in dinner in your city? Let us know and we’ll be there soon!</p>
          </FaqQuestion>
          <FaqQuestion
            customClass="question-group"
            question="When is MealPal available?"
            open={open === '#5'}
            toggle={() => toggle('#5')}
            utmContent={new UtmContent({ position: 'when-available' }).toString()}
          >
            <p>
              MealPal is available weekdays except on the following holidays: New Year’s Day, Australia Day, Good
              Friday, Easter Monday, Anzac Day, Christmas Day, Boxing Day.
            </p>
            <p>
              In cases where a holiday falls on a weekend, it is possible that MealPal will observe that holiday on the
              closest weekday.
            </p>
          </FaqQuestion>
          <FaqQuestion
            customClass="question-group"
            question="What does my plan cover?"
            open={open === '#6'}
            toggle={() => toggle('#6')}
            utmContent={new UtmContent({ position: 'what-does-plan-cover' }).toString()}
          >
            <p>
              Your lunch subscription covers 100% of the cost of your lunch. There are no additional charges when you
              pick up your lunch. You will save up to 50% on the restaurant prices when using MealPal.
            </p>
          </FaqQuestion>
          <FaqQuestion
            customClass="question-group"
            question="When does my MealPal start and end?"
            open={open === '#7'}
            toggle={() => toggle('#7')}
            utmContent={new UtmContent({ position: 'start-and-end' }).toString()}
          >
            <p>
              Your MealPal cycle begins the day you sign up for one of our plans. Cycles last 30 days and automatically
              renew after this time or when you have less than 10 credits remaining, whichever comes first. You can
              rollover up to half of your unused credits to your next continuous cycle.
            </p>
          </FaqQuestion>
          <FaqQuestion
            customClass="question-group"
            question="How do I pickup my meal?"
            open={open === '#8'}
            toggle={() => toggle('#8')}
            utmContent={new UtmContent({ position: 'pickup-meal' }).toString()}
          >
            <p>
              In order to pickup your meal, you need to have either the MealPal{' '}
              <a
                href="https://apps.apple.com/au/app/mealpal-best-meals-around-you/id1123086545"
                target="_blank"
                rel="noreferrer"
              >
                iOS app
              </a>
              or{' '}
              <a
                href="https://play.google.com/store/apps/details?id=com.mealpass.mealpal&hl=au"
                target="_blank"
                rel="noreferrer"
              >
                Android app
              </a>{' '}
              installed on your mobile device.
            </p>
            <p>
              Once at the restaurant, open your app and scan the MealPal QR code. Show the restaurant your confirmation
              screen. This screen will expire after 3 minutes, so make sure to scan the QR code just before showing the
              restaurant your confirmation.
            </p>
            <p>You must have a mobile device with a working camera and the MealPal app in order to pickup your meal.</p>
          </FaqQuestion>
          <FaqQuestion
            customClass="question-group"
            question="Does MealPal deliver?"
            open={open === '#9'}
            toggle={() => toggle('#9')}
            utmContent={new UtmContent({ position: 'delivery' }).toString()}
          >
            <p>
              Meals reserved on MealPal are for pick up only. With hundreds of restaurants on the platform, you’ll find
              plenty of options in your neighbourhood. Plus, a quick walk will help build up your appetite!
            </p>
          </FaqQuestion>
          <FaqQuestion
            customClass="question-group"
            question="Can I leave a tip for a restaurant?"
            open={open === '#10'}
            toggle={() => toggle('#10')}
            utmContent={new UtmContent({ position: 'tip-restaurant' }).toString()}
          >
            <p>
              Your MealPal subscription covers all costs. Tipping is not required or expected. If a restaurant goes
              above and beyond and you would like to leave them a tip, you will have the option to do so in the app.
              100% of tips goes to the restaurant!
            </p>
          </FaqQuestion>
          <FaqQuestion
            customClass="question-group"
            question="How do I cancel my account?"
            open={open === '#11'}
            toggle={() => toggle('#11')}
            utmContent={new UtmContent({ position: 'cancel-account' }).toString()}
          >
            <p>
              You can make changes by <a href="mailto:hi@mealpal.com">hi@mealpal.com</a>. You may change your plan,
              pause your account, or cancel your membership.
            </p>
            <p>
              Please note that all changes to your membership must be submitted at least seven business days prior to
              the start date of your next 30-day cycle.{' '}
            </p>
          </FaqQuestion>
          <FaqQuestion
            customClass="question-group"
            question="Can I pause or hold my account?"
            open={open === '#12'}
            toggle={() => toggle('#12')}
            utmContent={new UtmContent({ position: 'hold-account' }).toString()}
          >
            <p>
              Yes. Email <a href="mailto:hi@mealpal.com">hi@mealpal.com</a> to pause your account. You can put your
              account on hold for up to three months.
            </p>
            <p>
              The hold will start at the end of your current cycle. You can remove the hold or change your end date at
              any time.
            </p>
          </FaqQuestion>
          <FaqQuestion
            customClass="question-group"
            question="How can I change my password?"
            open={open === '#13'}
            toggle={() => toggle('#13')}
            utmContent={new UtmContent({ position: 'change-password' }).toString()}
          >
            <p>
              Log out of your account and go to <a href={`${process.env.GATSBY_SERVER_URL}/login`}>the log in screen</a>
              . Then, select the “Forgot?” button to update your password.
            </p>
          </FaqQuestion>
          <FaqQuestion
            customClass="question-group"
            question="Can I give MealPal as a gift?"
            open={open === '#14'}
            toggle={() => toggle('#14')}
            utmContent={new UtmContent({ position: 'gift-mp' }).toString()}
          >
            <p>
              Yes! Please email us at <a href="mailto:hi@mealpal.com">hi@mealpal.com</a> and we can help set you up with
              a gift account.
            </p>
          </FaqQuestion>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Faq
